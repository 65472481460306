@media (min-width: 1920px) {
  .container {
    max-width: 1680px;
  }
}

button{
  font-family: inherit !important;
}

.bundle-table .overflow-column { 
  max-width: 8vw;
  text-overflow: ellipsis;
  overflow: hidden;
}

.loader {
  height: 40px;
  width: 40px;
  animation: spin 1s linear infinite;
  border: 3px solid lightgray;
  border-top: 3px solid transparent;
  border-radius: 50%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.page-content{
  width: 100%;
  padding: 50px;
}

/* form */ 
.product-info{
  gap: 8px;
}
.product-info button{
  position: absolute;
  right: -15px;
  top: calc(50% - 11px);
}

.product-input{
  margin: 0;
  flex: 0 0 calc(100% / 3 - 8px);
}

.product-images-input{
  margin: 0;
  flex: 0 0 calc(100% / 2 - 8px);
}

.separator-margin {
  margin: 15px 0 !important;
}

.separator-margin--large {
  margin-bottom: 25px !important;
  border-color: #2a2a2a;
}

.file-uploader-alt {
  margin: 0;

  > label {
    background-color: #e5e7eb;
  }
}